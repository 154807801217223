
import { Mixins, Component } from "vue-property-decorator";
import GlobalMixin from "@/mixins/GlobalMixin";
import PageTitle from "@/components/common/PageTitle.vue";
import { Course } from "@planetadeleste/vue-mc-learning";
import CourseView from "@/modules/courses/components/CourseView.vue";

@Component({
  components: {
    PageTitle,
    CourseView,
  },
})
export default class CoursesView extends Mixins(GlobalMixin) {
  obCourse: Course = new Course();

  mounted(): void {
    this.$nextTick(this.load);
  }

  async load(): Promise<void> {
    const sSlug = this.$route.params.slug;
    if (!sSlug) {
      return;
    }

    this.loading();

    this.obCourse = new Course({ slug: sSlug });
    await this.obCourse.fetch();

    this.loaded();
  }
}
